<template>
  <v-app class="layout-assessment primary">
    <v-main>
      <router-view :key="$route.path"></router-view>
      <v-footer height="auto" class="pa-3 app--footer">
        <span>มูลนิธิแม่ฟ้าหลวง &copy; {{ new Date().getFullYear() }} </span>
        <v-spacer />
        <span class="caption mr-1">v{{ $store.getters.getVersion }}</span>
        <!-- <v-icon color="pink" small>favorite</v-icon> -->
      </v-footer>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      showDrawer: true
    }
  },
  methods: {},
  created() {},
  computed: {
    getVersion() {
      return this.$store.getters.getVersion
    }
  }
}
</script>
<style lang="sass" scoped>
.layout-assessment
  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0
</style>
