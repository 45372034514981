const state = {
  forms: [],
  activeField: [],
  activeTabForFields: 'elements',
  pages: []
}

// getters
const getters = {
  getForms: (state) => {
    return state.forms
  },
  getFormsCount: (state) => {
    return state.forms.length
  },
  getActiveField: (state) => {
    return state.activeField
  },
  getActiveTabForFields: (state) => {
    return state.activeTabForFields
  },
  pages: (state) => {
    return (parentId) => {
      if (!parentId) {
        return state.pages
      } else {
        const node = findDeep(state.pages, parentId)
        return node.children
      }
    }
  }
}

// actions
const actions = {
  addForms: ({ dispatch }, payload) => {
    dispatch('ADD_FORMS', payload)
  },
  setForms: ({ commit }, payload) => {
    // dispatch('SET_FORMS', payload)
    commit('SET_FORMS', payload)
  },
  deleteForms: ({ dispatch }, formIndex) => {
    dispatch('DELETE_FORMS', formIndex)
  },
  updateForms: ({ dispatch }, payload) => {
    dispatch('UPDATE_FORMS', payload)
  },
  updateFormsTitle: ({ dispatch }, payload) => {
    dispatch('UPDATE_FORMS_TITLE', payload)
  },
  setActiveField: ({ commit }, payload) => {
    commit('SET_ACTIVE_FIELD', payload)
  },
  setActiveTabForFields: ({ dispatch }, payload) => {
    dispatch('SET_ACTIVE_TAB', payload)
  },
  editElementProperties: ({ commit }, { field, activeTab }) => {
    commit('SET_ACTIVE_TAB', activeTab)
    commit('SET_ACTIVE_FIELD', field)
    console.log('form ->', state.forms)
    console.log('activeField ->', state.activeField)
  }
}

// mutations
const mutations = {
  ADD_FORMS(state, form) {
    state.forms.push(form)
  },
  DELETE_FORMS(state, formIndex) {
    state.forms = state.forms.filter((value, index) => {
      return index != formIndex
    })
  },
  SET_FORMS(state, payload) {
    state.forms = payload
  },
  UPDATE_FORMS(state, { index, form }) {
    state.forms[index] = form
  },
  UPDATE_FORMS_TITLE(state, { index, title }) {
    state.forms[index].title = title
  },
  SET_ACTIVE_FIELD(state, payload) {
    state.activeField = payload
  },
  SET_ACTIVE_TAB(state, payload) {
    state.activeTabForFields = payload
  },
  setPages(state, { value, parentId }) {
    if (!parentId) state.pages = value
    else {
      let node = findDeep(state.pages, parentId)
      node.children = value
    }
  }
}

function findDeep(items, id) {
  if (!items) {
    return
  }

  for (const item of items) {
    // Test current object
    if (item.id === id) {
      return item
    }

    // Test children recursively
    const child = findDeep(item.children, id)
    if (child) {
      return child
    }
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
