import Vue from 'vue'
import Router from 'vue-router'
import { publicRoute, protectedRoute } from './config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '../store/'
const routes = publicRoute.concat(protectedRoute)

Vue.use(Router)
const router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  routes: routes
})

const waitForStorageToBeReady = async (to, from, next) => {
  NProgress.start()
  await store.restored

  console.log(store.getters.isLogin)
  // const isLogined = this.$store.getters.isLogin()
  // if (to.name === 'login' && isLogined) {
  //   return next({
  //     path: '/'
  //   })
  // }

  if (to.meta.requiresAuth && !store.getters.isLogin) {
    return next({
      path: '/auth/login'
    })
  }
  //auth route is authenticated
  next()
}
// router gards
router.beforeEach(waitForStorageToBeReady)

router.afterEach(() => {
  NProgress.done()
})

export default router
