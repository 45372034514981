// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }

const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const kebab = (str) => {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

const toggleFullScreen = () => {
  let doc = window.document
  let docEl = doc.documentElement

  let requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen
  let cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

const syncedDialog = (alert, synced) => {
  synced.all = synced.added + synced.exist
  const msg = `ทั้งหมด ${synced.all}<br/>รายการใหม่ ${synced.added}<br/> รายการอัพเดท ${synced.updated}<br/> ไม่สำเร็จ ${synced.fail}`
  alert(msg, 'ซิงค์ข้อมูล', {
    confirmButtonText: 'ปิด',
    dangerouslyUseHTMLString: true
  })
}
const goToLuSystem = (token, path) => {
  // const url = encodeURI(`http://lu.maefahluang.org/public${path}`)
  const goto = getLuUrl(path)
  console.info(goto)
  window.location = goto
}

const getBaseUrl = (path) => {
  const host = process.env.VUE_APP_BASE_URL
  if (path) {
    return `${host}${path}`
  }
  return host
}
const getLuUrl = (path) => {
  const host = process.env.VUE_APP_LU_URL
  if (path) {
    return `${host}${path}`
  }
  return host
}

const getAPIUrl = (path) => {
  const host = process.env.VUE_APP_API_URL
  if (path) {
    return `${host}${path}`
  }
  return host
}

export default {
  randomElement,
  toggleFullScreen,
  kebab,
  getBaseUrl,
  getLuUrl,
  getAPIUrl,
  goToLuSystem,
  syncedDialog
}
