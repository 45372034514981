<template>
  <v-app id="inspire" class="app dashboard">
    <app-drawer class="app--drawer" :show-drawer="showDrawer" />
    <app-toolbar class="app--toolbar" @side-icon-click="handleDrawerVisiable" />
    <v-main>
      <!-- Page Header -->
      <div class="page-wrapper"><router-view /></div>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer">
        <span>มูลนิธิแม่ฟ้าหลวง &copy; {{ new Date().getFullYear() }} </span>
        <v-spacer />
        <span class="caption mr-1">v{{ version }}</span>
        <!-- <v-icon color="pink" small>favorite</v-icon> -->
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </v-app>
</template>

<script>
import AppDrawer from '@/components/AppDrawer'
import AppToolbar from '@/components/AppToolbar'
import AppFab from '@/components/AppFab'
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutDefault',
  components: {
    AppDrawer,
    AppToolbar,
    AppFab
  },

  data() {
    return {
      showDrawer: true,
      version: '0.0.0'
    }
  },
  methods: {
    handleDrawerVisiable() {
      this.showDrawer = !this.showDrawer
    }
  },
  created() {
    this.version = this.getVersion
  },
  computed: {
    ...mapGetters(['getVersion'])
  }
}
</script>

<style lang="sass" scoped>
.page-wrapper
  min-height: calc(100vh - 64px - 50px - 81px)
</style>
