import {
  LayoutAuth,
  LayoutDefault,
  LayoutAssessment,
  LayoutChat
} from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue')
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: {
      title: 'Login',
      requiresAuth: false
    },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Login',
          requiresAuth: false
        },
        component: () => import('@/views/auth/Login.vue')
      },
      {
        path: 'logout',
        name: 'logout',
        meta: {
          title: 'Logout',
          requiresAuth: true
        },
        component: () => import('@/views/auth/Logout.vue')
      }
    ]
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not Found'
    },
    component: () => import('@/views/error/NotFound.vue')
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'Server Error'
    },
    component: () => import('@/views/error/Error.vue')
  }
]

export const protectedRoute = [
  {
    // Dashboard
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'Home',
      group: 'apps',
      icon: ''
    },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          title: 'Dashboard',
          group: 'apps',
          icon: 'dashboard',
          requiresAuth: true
        },
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: '/media',
        meta: {
          title: 'Media',
          group: 'apps',
          icon: 'media'
        },
        name: 'Media',
        component: () => import('@/views/Media.vue')
      },

      {
        path: '/calendar',
        meta: {
          title: 'กำหนดการ',
          group: 'apps',
          icon: 'media'
        },
        name: 'กำหนดการ',
        props: (route) => ({
          type: route.query.type
        }),
        component: () => import('@/views/Calendar.vue')
      },
      {
        path: '/403',
        name: 'Forbidden',
        meta: {
          title: 'Access Denied',
          hiddenInMenu: true
        },
        component: () => import('@/views/error/Deny.vue')
      }
    ]
  },

  //course
  {
    path: '/course',
    name: 'หลักสูตร',
    component: LayoutDefault,
    redirect: {
      path: '/course/list'
    },
    meta: {
      title: 'หลักสูตร',
      group: 'apps',
      icon: 'chat_bubble'
    },
    children: [
      {
        path: '/course/list',
        // name: 'รายละเอียดหลักสูตร',
        props: true,
        component: () => import('@/views/course/CourseList.vue')
      }
    ]
  },
  // activity
  {
    path: '/activity',
    name: 'กิจกรรม',
    component: LayoutDefault,
    redirect: {
      path: '/activity/list'
    },
    meta: {
      title: 'รายการกิจกรรม',
      group: 'apps',
      icon: 'local_activity'
    },
    children: [
      {
        path: '/activity/list',
        // name: 'รายละเอียดหลักสูตร',
        props: true,
        component: () => import('@/views/activity/ActivityList.vue')
      }
    ]
  },
  // Trips
  {
    path: '/trip',
    name: 'การเยี่ยมชม',
    component: LayoutDefault,
    redirect: {
      path: '/trip/list'
    },
    meta: {
      title: 'รายการเยี่ยมชม',
      group: 'apps',
      icon: 'local_activity'
    },
    children: [
      {
        path: '/trip/list',
        props: true,
        meta: {
          title: 'รายการเยี่ยมชม'
        },
        component: () => import('@/views/trip/TripList.vue')
      },
      {
        path: '/trip/:id/report',
        props: true,
        meta: {
          title: 'รายงาน'
        },
        component: () => import('@/views/trip/Report.vue')
      }
    ]
  },
  //lecturer
  {
    path: '/lecturer',
    name: 'วิทยากร',
    component: LayoutDefault,
    redirect: {
      path: '/lecturer/list'
    },
    meta: {
      title: 'วิทยากร',
      group: 'apps',
      icon: 'chat_bubble'
    },
    children: [
      {
        path: '/lecturer/list',
        name: 'รายการวิทยากร',
        props: true,
        component: () => import('@/views/lecturer/LecturerList.vue')
      }
    ]
  },
  //group
  {
    path: '/group',
    name: 'คณะศึกษาดูงาน',
    component: LayoutDefault,
    redirect: {
      path: '/group/list'
    },
    meta: {
      title: 'คณะศึกษาดูงาน',
      group: 'apps',
      icon: 'chat_bubble'
    },
    children: [
      {
        path: '/group/list',
        name: 'รายการคณะศึกษาดูงาน',
        props: true,
        component: () => import('@/views/group/GroupList.vue')
      }
    ]
  },
  //user
  {
    path: '/user',
    name: 'ผู้ใช้งาน',
    component: LayoutDefault,
    redirect: {
      path: '/user/list'
    },
    meta: {
      title: 'ผู้ใช้งาน',
      group: 'apps',
      icon: 'chat_bubble'
    },
    children: [
      {
        path: '/user/list',
        name: 'รายการผู้ใช้งาน',
        props: true,
        component: () => import('@/views/user/UserList.vue')
      }
    ]
  },
  //surveyForm
  {
    path: '/surveyForm',
    name: 'แบบประเมิน',
    component: LayoutDefault,
    redirect: {
      path: '/surveyForm/list'
    },
    meta: {
      title: 'ฟอร์มแบบประเมิน',
      group: 'apps',
      icon: 'chat_bubble',
      requiresAuth: true
    },
    children: [
      {
        path: '/surveyForm/list',
        name: 'รายการแบบประเมิน',
        props: true,
        component: () => import('@/views/surveyForm/FormList.vue')
      },
      {
        path: '/surveyForm/new',
        name: 'สร้างแบบประเมิน',
        meta: {
          title: 'สร้างแบบประเมิน'
        },
        props: true,
        component: () => import('@/views/surveyForm/FormDesign.vue')
      },
      {
        path: '/surveyForm/:id/edit',
        name: 'แก้ไขแบบประเมิน',
        meta: {
          title: 'แก้ไขแบบประเมิน'
        },
        props: true,
        component: () => import('@/views/surveyForm/FormDesign.vue')
      },
      {
        path: '/surveyForm/:id/designReport',
        name: 'ออกแบบรายกงาน',
        meta: {
          title: 'ออกแบบรายกงาน'
        },
        props: true,
        component: () => import('@/views/surveyForm/FormDesignReport.vue')
      }
    ]
  },
  // Report
  {
    path: '/reports',
    name: 'รายงาน',
    component: LayoutDefault,
    redirect: {
      path: '/reports/list'
    },
    meta: {
      title: 'รายงาน',
      group: 'apps',
      icon: '',
      requiresAuth: true
    },
    children: [
      {
        path: '/reports/list',
        name: 'รายการแบบประเมิน',
        props: true,
        component: () => import('@/views/reports/ReportList.vue')
      },
      {
        path: '/reports/lecturer',
        name: 'วิทยากร',
        meta: {
          title: 'วิทยากร'
        },
        props: true,
        component: () => import('@/views/reports/ReportLecturer.vue')
      }
    ]
  },
  //list
  {
    path: '/cms',
    component: LayoutDefault,
    redirect: '/cms/table',
    meta: {
      title: 'CMS',
      icon: 'view_compact',
      group: 'cms'
    },
    children: [
      {
        path: '/cms/table',
        name: 'ListTable',
        meta: {
          title: 'CMS Table'
        },
        component: () => import('@/views/list/Table.vue')
      }
    ]
  },

  //widgets
  {
    path: '/widgets',
    component: LayoutDefault,
    meta: {
      title: 'Widget',
      icon: 'widgets',
      group: 'advance'
    },
    redirect: '/widgets/chart',
    children: [
      {
        path: '/widgets/chart',
        name: 'ChartWidget',
        meta: {
          title: 'Chart Widget'
        },
        component: () => import('@/views/widgets/Chart.vue')
      },
      {
        path: '/widgets/list',
        name: 'ListWidget',
        meta: {
          title: 'List Widget'
        },
        component: () => import('@/views/widgets/List.vue')
      },
      {
        path: '/widgets/social',
        name: 'SocialWidget',
        meta: {
          title: 'Social Widget'
        },
        component: () => import('@/views/widgets/Social.vue')
      },
      {
        path: '/widgets/statistic',
        name: 'StatisticWidget',
        meta: {
          title: 'Statistic Widget'
        },
        component: () => import('@/views/widgets/Statistic.vue')
      }
    ]
  },
  // chat app
  {
    path: '/chat',
    name: 'Chat',
    component: LayoutChat,
    redirect: {
      path: '/chat/messaging'
    },
    meta: {
      title: 'Chat',
      group: 'apps',
      icon: 'chat_bubble'
    },
    children: [
      {
        path: '/chat/messaging/:uuid?',
        name: 'ChatMessaging',
        props: true,
        component: () => import('@/views/chat/ChatMessaging.vue')
      },
      {
        path: '/chat/contact/:uuid?',
        meta: {
          public: true
        },
        name: 'ChatContact',
        component: () => import('@/views/chat/ChatContact.vue')
      }
    ]
  },
  //mail app
  {
    path: '/mail',
    name: 'Mail',
    component: () => import('@/components/email/Layout.vue'),
    redirect: {
      path: '/mail/all'
    },
    children: [
      {
        path: '/mail/:mailType',
        name: 'MailIndex',
        component: () => import('@/components/email/List.vue')
      },
      {
        path: '/mail/0/:uuid',
        name: 'MailDetail',
        component: () => import('@/components/email/Reply.vue')
      }
    ]
  },
  // Assessment
  {
    path: '/f/:formId',
    name: 'redirect assessment form',
    meta: { requiresAuth: false },
    component: () => import('@/views/assessment/Welcome.vue')
  },
  {
    path: '/g/:grpupId',
    name: 'redirect assessment group',
    meta: { requiresAuth: false },
    component: () => import('@/views/assessment/Welcome.vue')
  },
  {
    path: '/t/:tripId',
    name: 'redirect assessment trip',
    meta: { requiresAuth: false },
    component: () => import('@/views/assessment/Welcome.vue')
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: LayoutAssessment,
    redirect: {
      path: '/assessment/welcome'
    },
    meta: {
      title: 'ประเมินผล',
      group: 'apps',
      icon: 'chat_bubble',
      requiresAuth: false
    },
    children: [
      {
        path: '/assessment/complete',
        name: 'ระบบประเมินผล สำเร็จ',
        props: true,
        component: () => import('@/views/assessment/Complete.vue')
      },
      {
        path: '/assessment/privacy',
        name: 'ข้อตกลงการคุ้มครองข้อมูลส่วนบุคคล',
        props: true,
        meta: {
          requiresAuth: false
        },
        component: () => import('@/views/assessment/Privacy.vue')
      },
      {
        path: '/assessment/welcome',
        name: 'ข้อตกลงเรื่องการรักษาข้อมูลส่วนตัว',
        props: true,
        component: () => import('@/views/assessment/Welcome.vue')
      },
      {
        path: '/assessment/welcome/:tripId',
        name: 'ข้อตกลงเรื่องการรักษาข้อมูลส่วนตัวคณะ',
        props: true,
        component: () => import('@/views/assessment/Welcome.vue')
      },
      {
        path: '/assessment/:tripId',
        name: 'ระบบประเมินผล',
        props: true,
        component: () => import('@/views/assessment/Assessment.vue')
      },
      {
        path: '/assessment/group/:groupId',
        name: 'ระบบประเมินผลคณะ',
        props: true,
        component: () => import('@/views/assessment/Assessment.vue')
      },
      {
        path: '/assessment/trip/:tripId',
        name: 'ระบบประเมินผลกิจกรรม',
        props: true,
        component: () => import('@/views/assessment/Assessment.vue')
      },
      {
        path: '/assessment/form/:formId',
        name: 'ระบบประเมินผลฟอร์ม',
        props: true,
        component: () => import('@/views/assessment/Assessment.vue')
      }
    ]
  }
]
