// import colors from 'vuetify/es5/util/colors'
import { uuid } from 'vue-uuid'
const state = {
  version: process.env.APP_VERSION || '0',
  mode: 'light',
  themeColor: 'pink',
  loginUser: null,
  uuid: '',
  token: '',
  acceptPolicy: false //localStorage.getItem('acceptPolicy') || false
}

// getters
const getters = {
  // getThemeColor: (state) => {
  //   return colors[state.themeColor].base
  // }
  getLoginUser: (state) => state.loginUser,
  getToken: (state) => state.token,
  getUUID: (state) => state.uuid,
  getIdentity: (state) => {
    return { loginUser: state.loginUser, uuid: state.uuid }
  },
  isLogin: (state) => {
    return state.loginUser ? true : false
  },
  isAuthenticated: (state) => !!state.token,
  isAcceptPolicy: (state) => state.acceptPolicy,
  getVersion: (state) => state.version
}

// actions
const actions = {
  // setThemeColor({ commit, state }, payload) {
  //   commit('setThemeColor')
  //   // state.themeColor = payload
  // }
  fetchUUID({ commit }) {
    const newUUID = localStorage.getItem('uuid') || uuid.v4()
    commit('SET_UUID', newUUID)
    return newUUID
  },
  setLoginUser({ commit }, user) {
    commit('SET_LOGIN_USER', user)
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
  logout({ commit }) {
    commit('LOGOUT')
  },
  setUUID({ commit }, uuid) {
    commit('SET_UUID', uuid)
  },
  createUUID({ commit }) {
    console.log('createUUID')
    const newUUID = uuid.v4()
    commit('SET_UUID', newUUID)
    return newUUID
  },
  setAcceptPolicy({ commit }) {
    commit('SET_ACCEPT_POLICY', true)
  },
  setVersion({ commit }, version) {
    commit('SET_VERSION', version)
  }
}

// mutations
const mutations = {
  themeColor(state, payload) {
    state.themeColor = payload
  },
  // setThemeColor(state, payload) {
  //   state.themeColor = payload
  // }
  LOGOUT(state) {
    state.loginUser = null
  },
  SET_LOGIN_USER(state, user) {
    // user.map((u) => {
    //   u.fullname = `${u.forst_name} ${u.last_name}`
    //   return u
    // })
    state.loginUser = user
  },
  SET_TOKEN(state, token) {
    state.token = token
    console.log(token)
  },
  SET_UUID(state, uuid) {
    state.uuid = uuid
    // localStorage.setItem('uuid', uuid)
  },
  SET_ACCEPT_POLICY(state, isAcceptPolicy) {
    // state.acceptPolicy = true
    if (isAcceptPolicy) {
      state.acceptPolicy = isAcceptPolicy
      // localStorage.setItem('acceptPolicy', state.acceptPolicy)
    }
  },
  SET_VERSION(state, version) {
    state.version = version
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
