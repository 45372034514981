import Vue from 'vue'
import router from './router/'
import store from './store/'
import './registerServiceWorker'
import './plugins/vuetify'
import './theme/default.sass'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

// ================
// Lodash
// ----------------
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom', lodash: lodash })

// ================
// Vue-stash aka simple vuex alternative
// ----------------
// import VueStash from 'vue-stash'
// Vue.use(VueStash)

// ================
// Use Element UI
// ----------------
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // Default lang is Chinese
Vue.use(ElementUI, { locale })

import Notifications from 'vue-notification'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(Notifications)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
})
app.$mount('#app')

export default app
