const state = {
  report: { title: 'ชื่อฟอร์ม', fields: [] },
  activeField: [],
  activeTabForFields: 'elements',
  pages: []
}

// mutations
const mutations = {
  ADD_REPORT(state, report) {
    state.report.push(report)
  },
  DELETE_REPORT(state, reportIndex) {
    state.reports = state.report.filter((value, index) => {
      return index != reportIndex
    })
  },
  SET_REPORT(state, payload) {
    state.report = payload
  },
  UPDATE_REPORT(state, { report }) {
    state.report = report
  },
  UPDATE_REPORT_TITLE(state, { title }) {
    state.report.title = title
  },
  SET_REPORT_ACTIVE_FIELD(state, payload) {
    state.activeField = payload
  },
  SET_REPORT_ACTIVE_TAB(state, payload) {
    state.activeTabForFields = payload
  },
  setReportPages(state, { value, parentId }) {
    if (!parentId) state.pages = value
    else {
      let node = findDeep(state.pages, parentId)
      node.children = value
    }
  }
}

// getters
const getters = {
  getReport: (state) => {
    return state.report
  },
  getReportCount: (state) => {
    return state.report.length
  },
  getReportActiveField: (state) => {
    return state.activeField
  },
  getReportActiveTabForFields: (state) => {
    return state.activeTabForFields
  },
  reportPages: (state) => {
    return (parentId) => {
      if (!parentId) {
        return state.pages
      } else {
        const node = findDeep(state.pages, parentId)
        return node.children
      }
    }
  }
}

// actions
const actions = {
  addReport: ({ dispatch }, payload) => {
    dispatch('ADD_REPORT', payload)
  },
  setReport: ({ dispatch }, payload) => {
    dispatch('SET_REPORT', payload)
  },
  deleteReport: ({ dispatch }, formIndex) => {
    dispatch('DELETE_REPORT', formIndex)
  },
  updateReport: ({ dispatch }, payload) => {
    dispatch('UPDATE_REPORT', payload)
  },
  updateReportTitle: ({ dispatch }, payload) => {
    dispatch('UPDATE_REPORT_TITLE', payload)
  },
  setReportActiveField: ({ commit }, payload) => {
    commit('SET_REPORT_ACTIVE_FIELD', payload)
  },
  setActiveTabForFields: ({ dispatch }, payload) => {
    dispatch('SET_REPORT_ACTIVE_TAB', payload)
  },
  editElementProperties: ({ commit }, { field, activeTab }) => {
    commit('SET_REPORT_ACTIVE_TAB', activeTab)
    commit('SET_REPORT_ACTIVE_FIELD', field)
    // console.log('form ->', state.forms)
    // console.log('activeField ->', state.activeField)
  }
}

function findDeep(items, id) {
  if (!items) {
    return
  }

  for (const item of items) {
    // Test current object
    if (item.id === id) {
      return item
    }

    // Test children recursively
    const child = findDeep(item.children, id)
    if (child) {
      return child
    }
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
