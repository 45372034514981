const Menu = [
  {
    header: 'ระบบประเมินผลคณะศึกษาดูงาน'
  },
  {
    text: 'หน้าหลัก',
    icon: 'dashboard',
    to: '/dashboard'
  },
  {
    text: 'หลักสูตร',
    icon: 'book_online',
    target: '_blank',
    to: '/course'
  },
  {
    text: 'กิจกรรม',
    icon: 'local_activity',
    target: '_blank',
    to: '/activity'
  },
  {
    text: 'ฟอร์มแบบประเมิน',
    icon: 'chat_bubble',
    target: '_blank',
    to: '/surveyForm'
  },
  {
    text: 'การเยี่ยมชม',
    icon: 'mdi-briefcase-clock',
    target: '_blank',
    to: '/trip'
  },
  {
    text: 'วิทยากร',
    icon: 'record_voice_over',
    target: '_blank',
    to: '/lecturer'
  },
  {
    text: 'คณะศึกษาดูงาน',
    icon: 'group_work',
    target: '_blank',
    to: '/group'
  },
  {
    text: 'กำหนดการ',
    icon: 'schedule',
    target: '_blank',
    to: '/calendar'
  },
  {
    text: 'ผู้ใช้งาน',
    icon: 'account_box',
    target: '_blank',
    to: '/user'
  },
  {
    text: 'รายงาน',
    group: 'reports',
    to: '/reports',
    icon: 'assessment',
    children: [
      {
        to: '/reports/lecturer',
        text: 'วิทยากร'
      },
      {
        to: '/reports/statistic',
        text: 'สถิติการเข้าอบรม'
      }
    ]
  },
  {
    text: 'ออกจากระบบ',
    icon: 'exit_to_app',
    target: '_blank',
    to: '/logout'
  }
  // {
  //   text: 'Chat',
  //   icon: 'chat_bubble',
  //   target: '_blank',
  //   to: '/chat'
  // },
  // {
  //   text: 'Inbox',
  //   to: '/mail',
  //   target: '_blank',
  //   icon: 'email'
  // },
  // {
  //   text: 'Media',
  //   to: '/media',
  //   icon: 'perm_media'
  // },
  // {
  //   text: 'Widgets',
  //   group: 'widgets',
  //   to: '/widgets',
  //   icon: 'widgets',
  //   children: [
  //     {
  //       to: '/widgets/social',
  //       text: 'Social'
  //     },
  //     {
  //       to: '/widgets/statistic',
  //       text: 'Statistic',
  //       badge: 'new'
  //     },
  //     {
  //       to: '/widgets/chart',
  //       text: 'Chart'
  //     },
  //     {
  //       to: '/widgets/list',
  //       text: 'List'
  //     }
  //   ]
  // },
  // {
  //   header: 'CMS'
  // },
  // {
  //   text: 'List & Query',
  //   group: 'layout',
  //   to: 'cms',
  //   icon: 'view_compact',
  //   children: [
  //     {
  //       to: '/cms/table',
  //       text: 'Basic Table'
  //     }
  //   ]
  // }
]

export default Menu
